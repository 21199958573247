



















import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Model,
} from "vue-property-decorator";
import AbSelect from "@/components/AbSelect/index.vue";
import api from "@/api";
import { ExternalProjectDto, InternalProjectDto } from "@/api/appService";

@Component({
  name: "projectSelect",
  components: {
    AbSelect,
  },
})
export default class projectSelect extends Vue {
  @Model("change", { required: true, default: "" }) readonly value!:
    | number
    | string;

  @Prop({ required: false, default: false })
  disabled!: boolean;

  @Prop({ required: false, default: true })
  isInternal!: boolean;

  @Prop({ required: false, default: false })
  clearable!: boolean;

  private val: number | string = "";
  @Watch("value", { deep: true })
  valueChange(newVal: string | number, oldVal: string | number) {
    this.val = newVal;
  }

  @Watch("val")
  onValueChange(newVal: string | number, oldVal: string | number) {
    if (!oldVal && this.dataId) return;
    this.$emit("change", newVal);
  }

  private projectList: ExternalProjectDto[] | InternalProjectDto[] | undefined =
    [];
  dataId = 0;
  created() {
    this.val = this.value;
    this.dataId = Number(this.$route.params.id);
    this.fetchProject();
  }

  fetchProject() {
    let fn = undefined;
    if (this.isInternal) {
      fn = api.internalProject.getInternalProjectList;
    } else {
      fn = api.externalProject.getExternalProjectList;
    }

    fn().then((res) => {
      this.projectList = res;
    });
  }
}
