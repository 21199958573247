

















































































































































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import api from "@/api";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import {
  ExternalDueDiligenceRecordDto,
  AuditFlowScope,
} from "@/api/appService";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import AuditFlowDetailButton from "@/components/AuditFlowDetailButton/index.vue";

@Component({
  name: "dueDiligenceRecord",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
    AuditFlowDetailButton,
  },
})
export default class DueDiligenceRecordList extends Vue {
  tableItems: ExternalDueDiligenceRecordDto[] = [];
  queryForm: any = {
    dueDiligenceTypeId: undefined,
    startDate: undefined,
    startDateStart: undefined,
    startDateEnd: undefined,
    endDate: undefined,
    endDateStart: undefined,
    endDateEnd: undefined,
    place: undefined,
    members: undefined,
    visitObjectJson: undefined,
    orgInfo: undefined,
    projectInfo: undefined,
    omnibusOpinion: undefined,
    organizationUnitName: undefined,
    projectName: undefined,
    organizationUnitId: undefined,
  };

  dueDiligenceTypeList?: any = [];

  created() {
    api.dataDictionary
      .getDataDictionaryListByKey({
        key: "DueDiligenceType",
      })
      .then((res) => {
        this.dueDiligenceTypeList = res.items;
      });

    if (this.$route.query.orgId) {
      this.queryForm.organizationUnitId = this.$route.query.orgId;
    }
  }

  fetchData(params: any) {
    return api.externalDueDiligenceRecord.getAll(params);
  }

  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;

      case "edit":
        this.handleEdit($row.index, $row.item);
        break;

      case "delete":
        this.handleDelete($row.index, $row.item);
        break;

      case "startAudit":
        this.handleStartAudit($row.index, $row.item);
        break;

      case "auditFlow":
        this.handleAuditFlow($row.index, $row.item);
        break;
    }
  }

  handleCreate() {
    this.$router.push({ name: "dueDiligenceRecordCreate" });
  }

  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "dueDiligenceRecordEdit",
      params: { id: row.id!.toString() },
    });
  }

  handleDetail(row: ExternalDueDiligenceRecordDto) {
    this.$router.push({
      name: "dueDiligenceRecordDetail",
      params: { id: row.id!.toString() },
    });
  }

  // 删除
  async handleDelete(index: number, row: ExternalDueDiligenceRecordDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.externalDueDiligenceRecord
        .delete({ id: row.id })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }

  async handleStartAudit(index: number, row: ExternalDueDiligenceRecordDto) {
    // await api.externalDueDiligenceRecord.startAudit({body:{id: row.id}}).then(res => {
    //     this.$message.success("已提交");
    // });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.DueDiligenceRecord,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }
}
