import { render, staticRenderFns } from "./AuditBlock.vue?vue&type=template&id=12fcc592&"
import script from "./AuditBlock.vue?vue&type=script&lang=ts&"
export * from "./AuditBlock.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports