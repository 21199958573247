




















































































import { Component, Ref, Vue, Prop } from "vue-property-decorator";
import {
  AuditFlowScope,
  ExternalDueDiligenceRecordDto,
} from "@/api/appService";
import api from "@/api";
import ExportWord from "@/components/DownloadWord/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";


@Component({
  name: "dueDiligenceRecordDetail",
  components: {
    ExportWord,
    AuditBlock,
  },
})
export default class DueDiligenceRecordOrganizationDetail extends Vue {

  dueDiligenceRecordId?: number;
  detail: ExternalDueDiligenceRecordDto = {};
  visitObjects: any = [
    { role: undefined, name: undefined, position: undefined },
  ];

  selector = "table-detail";
  filename = "项目尽职报告";
  styles = "";


  created() {
    if (this.$route.params.id) {
      this.dueDiligenceRecordId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }


  get auditApi(){
    return {canAudit:
      api.externalDueDiligenceRecord.canAudit,
      userAudit: api.externalDueDiligenceRecord.audit} as AuditApi
  }

  // 获取详情
  async fetchDetail() {
    await api.externalDueDiligenceRecord
      .getDetail({ id: this.dueDiligenceRecordId })
      .then((res) => {
        this.detail = { ...res };

        this.visitObjects = JSON.parse(this.detail.visitObjectJson!);
        console.log(this.visitObjects);
      });
  }


  // 返回
  cancel() {
    this.$router.back();
  }
}
