

































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import { ExternalDueDiligenceRecordCreateOrUpdateDto } from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import AbCard from "@/components/AbCard/index.vue";

@Component({
  components: {
    ProjectSelect,
    AbSelect,
    AbCard,
  },
})
export default class CreateDueDiligenceReord extends Vue {
  @Ref() readonly dataForm!: ElForm;
  form: any = {
    organizationUnitId: (this as any).$store.getters["user/outId"],
    projectId: undefined,
    dueDiligenceTypeId: undefined,
    startDate: undefined,
    endDate: undefined,
    place: undefined,
    members: undefined,
    visitObjectJson: undefined,
    orgInfo: undefined,
    projectInfo: undefined,
    omnibusOpinion: undefined,
    id: 0,
  };

  visitObjects: any = [
    { role: undefined, name: undefined, position: undefined },
  ];

  projectType = "";

  dueDiligenceRecordId?: number;
  dueDiligenceTypeList: any = [];

  created() {
    if (this.$route.params.id) {
      this.dueDiligenceRecordId = Number(this.$route.params.id);
      api.externalDueDiligenceRecord
        .get({ id: this.dueDiligenceRecordId })
        .then((res) => {
          this.form = { ...res };
          this.visitObjects = JSON.parse(this.form.visitObjectJson!);
        });
    }

    api.dataDictionary
      .getDataDictionaryListByKey({ key: "DueDiligenceType" })
      .then((res) => {
        this.dueDiligenceTypeList = res.items;
      });
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          this.form.visitObjectJson = JSON.stringify(
            this.visitObjects
          ).toString();
          await api.externalDueDiligenceRecord.update({
            body: this.form,
          });
        } else {
          this.form.visitObjectJson = JSON.stringify(
            this.visitObjects
          ).toString();
          await api.externalDueDiligenceRecord.create({
            body: this.form,
          });
        }
        this.$router.back();
        this.$message.success("更新成功");
      }
    });
  }

  cancel() {
    this.$router.back();
  }

  private addRow(r: number) {
    let obj = {
      role: undefined,
      name: undefined,
      position: undefined,
    };

    this.visitObjects = [...this.visitObjects!, obj];
  }

  // 删除整行
  private deleteRow(index: number) {
    if (this.visitObjects!.length === 1) {
      return;
    }
    this.visitObjects!.splice(index, 1);
  }

  dueDiligenceRule = {
    dueDiligenceTypeId: [
      {
        required: true,
        message: "尽调形式必填",
        trigger: "blur",
      },
    ],
    startDate: [
      {
        required: true,
        message: "开始时间必填",
        trigger: "blur",
      },
    ],
    endDate: [
      {
        required: true,
        message: "结束时间必填",
        trigger: "blur",
      },
    ],
    projectId: [
      {
        required: true,
        message: "项目名称必填",
        trigger: "blur",
      },
    ],
    place: [
      {
        required: true,
        message: "尽调地点必填",
        trigger: "blur",
      },
    ],
    members: [
      {
        required: true,
        message: "尽调人员必填",
        trigger: "blur",
      },
    ],
    orgInfo: [
      {
        required: true,
        message: "机构情况必填",
        trigger: "blur",
      },
    ],
    projectInfo: [
      {
        required: true,
        message: "项目情况必填",
        trigger: "blur",
      },
    ],
    omnibusOpinion: [
      {
        required: true,
        message: "综合意见必填",
        trigger: "blur",
      },
    ],
    role: [
      {
        required: true,
        message: "项目角色必填",
        trigger: "blur",
      },
    ],
    name: [
      {
        required: true,
        message: "姓名必填",
        trigger: "blur",
      },
    ],
    posiiton: [
      {
        required: true,
        message: "机构职位必填",
        trigger: "blur",
      },
    ],
    visitObjectJson: [
      {
        required: true,
        message: "尽调对象必填",
        trigger: "blur",
      },
    ],
  };
}
