




























import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import api from '@/api'
import BatchUpload from "@/components/BatchUpload/index.vue";
import {AuditUserLogStatus, GuidEntityDto, Int32EntityDto, Int64EntityDto, UserAuditInput} from "@/api/appService";

export type CanAudit = (params:{body:{id:string | number}}) => Promise<boolean>;
export type UserAudit = (params:{body:UserAuditInput}) => Promise<void>;

export interface AuditApi {
  canAudit:CanAudit,
  userAudit:UserAudit
}

@Component({
  name: 'AuditBlock',
  components: {BatchUpload}
})
export default class AuditBlock extends Vue {


  isCanAudit = false
  disabledSubmitButton = false;
  isAuditSubmitCompleted = false;

  formData = {
    comment:'',
    auditAttachments: [],
  }


  @Prop({required:true})
  auditApi!:AuditApi;

  @Prop({required:true})
  hostId:string | number | undefined = undefined

  @Prop({required:false})
  isStarRatingRecordAudit = false;

  @Prop({required:false})
  reportComment?:string = '';

  @Prop({required:false})
  interviewComment?:string = '';



  @Watch('hostId')
  onHostIdChange(newValue: string | number | undefined){
    if(newValue){
      setTimeout(this.checkCanAudit,100);
    }
  }



  checkCanAudit() {
    this.auditApi.canAudit({body:{id:this.hostId!}}).then(res=>{
      this.isCanAudit = res;
    })
  }

  handleAudit(action:string){
    // this.$emit('postComment',action)

    if(this.isStarRatingRecordAudit){
      this.formData.comment = this.reportComment!
    }

    if(action === 'reject' && !this.checkComment(true)){
      return;
    }


    if(!this.isStarRatingRecordAudit){
      const requestBody = {
        hostId:this.hostId,
      } as UserAuditInput;
      if(action == 'pass'){
        requestBody.desc = '审核通过。'
        requestBody.status = AuditUserLogStatus.Pass
      } else if(action === 'reject') {
        requestBody.desc = '审核拒绝。';
        requestBody.status = AuditUserLogStatus.Reject;
      }
      if(this.formData.comment){
        requestBody.desc += '意见：'+this.formData.comment;
      }
      requestBody.saveAttachments = this.formData.auditAttachments;
      debugger
      console.log(requestBody)
      this.disabledSubmitButton = true;
      this.auditApi.userAudit({body:requestBody}).then((res:any)=>{
        this.disabledSubmitButton = false;
        this.isAuditSubmitCompleted = true;
        this.$message.success('操作成功！');
        this.$router.back();
        this.$emit('audit-completed')
      }).catch(()=>{
        this.disabledSubmitButton = false;
      })
    } else {
      this.disabledSubmitButton = true;
      api.starRatingRecord.growthReportAndInterviewReview({
        body:{
          reportComment: this.reportComment,
          interviewComment:this.interviewComment,
          hostId: String(this.hostId),
          desc: (action == 'pass'?'审核通过。':'审核拒绝。') + '意见：'+this.formData.comment,
          status: action == 'pass'?AuditUserLogStatus.Pass:AuditUserLogStatus.Reject,
          saveAttachments: this.auditAttachments
        }
      }).then((res:any) => {
        this.$emit('completed');
        this.disabledSubmitButton = false;
        this.isAuditSubmitCompleted = true;
        this.$message.success('操作成功！');
        this.$emit('audit-completed')
      }).catch(()=>{
        this.disabledSubmitButton = false;
      })
    }

  }

  checkComment(required:boolean):boolean{
    if(required && !this.formData.comment){
      this.$message.error('拒绝时，必须填写审核意见');
      return false;
    }

    if(this.formData.comment.length>200){
      this.$message.error('审核意见内容长度不要超过200个字符');
      return false;
    }
    return true;
  }





}

