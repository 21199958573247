




import { Component, Prop, Vue } from "vue-property-decorator";
// import {AuditUserLogType} from "@/api/appService";

@Component({ name: "AuditFlowDetailButton" })
export default class AuditFlowDetailButton extends Vue {
  @Prop({ required: true })
  hostId!: number | string;

  @Prop({ required: true })
  hostType!: string;

  @Prop({ required: true })
  auditFlowId!: string;

  @Prop({ required: false, default: "mini" })
  size!: string;

  handleViewAuditDetail() {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: this.hostType,
        hostId: this.hostId.toString(),
        auditFlowId: this.auditFlowId,
      },
    });
  }
}
