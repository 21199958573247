
















import { Component, Prop, Vue, Model, Watch } from "vue-property-decorator";
import { AttachmentDto, AttachmentHostType } from "@/api/appService";
import api from "@/api/index";

interface FileItem {
  id: number;
  name: string;
  url: string;
}

@Component({ name: "BatchUpload" })
export default class BatchUpload extends Vue {
  @Model("change") readonly attachments!: number[];

  @Prop({ required: true })
  hostId!: string | number;

  @Prop({ required: true })
  hostType!: AttachmentHostType;

  @Prop({required:false,default:'选择文件'})
  buttonText!:string;

  private savedAttachments: number[] = [];
  private existsFileList: FileItem[] = [];

  get uploadParameter() {
    return { hostType: this.hostType, hostId: this.hostId };
  }

  get headers() {
    return {
      "Abp-OrganizationUnitId": (this as any).$store.getters["user/outId"],
      "Abp-TenantId": (this as any).$store.getters["app/tenantId"],
      Authorization: `Bearer ${(this as any).$store.getters["user/token"]}`,
      ".AspNetCore.Culture": "c=zh-Hans|uic=zh-Hans",
    };
  }

  @Watch("hostId")
  onHostIdChange() {
    this.loadExistsAttachments();
  }

  @Watch("hostType")
  onHostTypeChange() {
    this.loadExistsAttachments();
  }

  async created() {
    await this.loadExistsAttachments();
  }

  async loadExistsAttachments() {
    if (this.hostId && this.hostType) {
      await api.attachmentService
        .getAttachments({
          hostId: this.hostId.toString(),
          hostType: this.hostType,
        })
        .then((res) => {
          if (res) {
            this.existsFileList = res.map((attachment) => {
              return {
                id: attachment.id!,
                url: attachment.url!,
                name: attachment.fileName!,
              };
            });
            this.savedAttachments = res.map((attachment) => attachment.id!);
          } else {
            this.existsFileList = [];
            this.savedAttachments = [];
          }
          this.$emit("change", this.savedAttachments);
        });
    }
  }

  async handleSuccess(res: any, file: any) {
    if (res.success) {
      this.savedAttachments = [
        ...this.savedAttachments,
        res.result.attachmentId,
      ];
      this.$emit("change", this.savedAttachments);
    }
  }

  async handleRemove(file: any, fileList: any) {
    let newValue: number[] = [];
    for (let i = 0; i < this.savedAttachments.length; i++) {
      let id = this.savedAttachments[i];
      if (id !== file.id) {
        newValue.push(id);
      }
    }
    this.savedAttachments = newValue;
    this.$emit("change", this.savedAttachments);
  }
}
